










import { Component, Prop, Vue } from "vue-property-decorator";
import FscPageHeader from "@/components/FscPageHeader.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import { IEmailTemplateCategory } from "@/interfaces/IEmailTemplateCategory";
import EmailTemplateCategoryItem from "@/views/Communication/EmailTemplateCategoryItem.vue";

@Component({
  components: { EmailTemplateCategoryItem, FscSimpleCard, FscPageHeader },
})
export default class EmailTemplateCategories extends Vue {
  public name = "EmailTemplateCategories";

  @Prop({ type: Array, default: () => [] })
  public emailTemplateCategories!: Array<any>;
}
